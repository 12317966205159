<template>
  <div>
    <div class="filter-panel">
      <input
        type="text"
        placeholder="搜索姓名/手机号/所属企业"
        style="margin-left: 0"
        v-model="keyword"
        class="cs-input"
      />
      <button
        type="button"
        class="btn btn-primary btn-sm"
        @click="queryUserList()"
      >
        查询
      </button>
    </div>

    <div class="result-panel">
      <CSTable :thead-top="filterHeight">
        <template v-slot:thead>
          <tr>
            <th class="text-center">注册时间</th>
            <th class="text-center">昵称</th>
            <th class="text-center">姓名</th>
            <th class="text-center">性别</th>
            <th class="text-center">手机号</th>
            <th class="text-center">生日</th>
            <th class="text-center">所属企业</th>
            <th class="text-center">所在房间</th>
            <th class="text-center">详细信息</th>
          </tr>
        </template>
        <template v-slot:tbody>
          <tr v-for="user in userList" :key="user.id">
            <td class="text-center" style="min-width: 125px">
              {{ user.createTime || "-" }}
            </td>
            <td class="text-center">
              {{ user.nickName || "-" }}
            </td>
            <td class="text-center">
              {{ user.realName || "-" }}
            </td>
            <td class="text-center">
              {{ user.gender === 1 ? "男" : user.gender === 2 ? "女" : "未知" }}
            </td>
            <td class="text-center">
              {{ user.phone || "-" }}
            </td>
            <td class="text-center">
              {{ user.birthday || "-" }}
            </td>
            <td class="text-center">
              {{ user.companyName || "-" }}
            </td>
            <td class="text-center">
              {{ user.rooms || "-" }}
            </td>
            <td class="text-center">
              {{ user.detail || "-" }}
            </td>
          </tr>
        </template>
      </CSTable>
      <Pagination />
    </div>
  </div>
</template>

<script>
import { queryIdentityList } from "@/requestUrl";
import CSTable from "@/components/common/CSTable";
import Pagination from "@/components/Pagination.vue";

export default {
  name: "Deputy",
  components: {
    CSTable,
    Pagination,
  },
  data() {
    return {
      keyword: "",
      filterHeight: 0,
      userList: [],
    };
  },
  mounted(){
    this.filterHeight = document.querySelector('.filter-panel').offsetHeight;
  },
  created() {
    window.addEventListener("keydown", this.queryUserListDown);
    this.queryUserList();
    this.$vc.on(
      this.$route.path,
      "pagination_page",
      "event",
      this.queryUserList
    );
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.queryUserListDown);
  },
  destroyed() {
    window.removeEventListener("keydown", this.queryUserListDown);
  },
  methods: {
    queryUserListDown(e) {
      if (e.keyCode == 13) {
        this.queryUserList();
      }
    },
    queryUserList(pageNo = 1, pageSize = 10) {
      const params = {
        regionCode: this.$vc.getCurrentRegion().communityId,
        search: this.keyword,
        pageNo,
        pageSize,
        deputy: true,
      };
      this.$fly.post(queryIdentityList, params).then((res) => {
        if (res.code !== 0) {
          return;
        } else {
          if (pageNo === 1) {
            this.$vc.emit(this.$route.path, "pagination", "init", {
              total: res.data.total,
              pageSize,
              currentPage: pageNo,
            });
          }
        }
        this.userList = res.data.datas;
      });
    },
  },
};
</script>

<style scoped>
</style>
